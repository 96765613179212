:root {
  /* TYPOGRAPHY */
  --display: 'Poppins';
  --body: 'Poppins';

  /* COLORS */
  --brand-light: #F4F8FD;
  --brand-base: #444cf0;
  --brand-dark: #343955;
  --brand-secondary: #344b98;
  --brand-highlight: #febc30;
  --000: #FFFFFF;
  --200: #F2F2F2;
  --400: #CCCCCC;
  --600: #4A4A4A;
  --800: #202020;
  --error: #E74C3C;
  --warning: #FEBC30;
  --success: #2ECC71;
  /* Teste Lucas */

  /* BUTTON */
  --btn-height: 48px;
  --btn-max-width: 360px;
  --btn-radius: 48px;
  --btn-border: 2px;

  /* BREAKPOINTS */
  --desktop: 1440px;
  --tablet: 991px;
  --mobile-landscape: 767px;
  --mobile-portrait: 478px;
}

/* Background Colors */
.bg-brand-light {
  background: var(--brand-light);
}
.bg-brand-base {
  background: var(--brand-base);
}
.bg-brand-dark {
  background: var(--brand-dark);
}
.bg-brand-secondary {
  background: var(--brand-secondary);
}
.bg-brand-highlight {
  background: var(--brand-highlight);
}
.bg-000 {
  background: var(--brand-000);
}
.bg-200 {
  background: var(--200);
}
.bg-400 {
  background: var(--400);
}
.bg-600 {
  background: var(--600);
}
.bg-800 {
  background: var(--800);
}
.bg-error {
  background: var(--brand-error);
}
.bg-warning {
  background: var(--warning);
}
.bg-success {
  background: var(--success);
}

/* Text Colors */
.txt-brand-light {
  color: var(--brand-light);
}
.txt-brand-base {
  color: var(--brand-base);
}
.txt-brand-dark {
  color: var(--brand-dark);
}
.txt-brand-secondary {
  color: var(--brand-secondary);
}
.txt-brand-highlight {
  color: var(--brand-highlight);
}
.txt-000 {
  color: var(--brand-000);
}
.txt-200 {
  color: var(--200);
}
.txt-400 {
  color: var(--400);
}
.txt-600 {
  color: var(--600);
}
.txt-800 {
  color: var(--800);
}
.txt-error {
  color: var(--brand-error);
}
.txt-warning {
  color: var(--warning);
}
.txt-success {
  color: var(--success);
}

/* Typo */
* [class*='display'] {
  font-family: var(--display)!important;
}
* {
  font-family: var(--body)!important;
}

/* Typo Headlines */
.is-display {
  font-size: 80px;
  line-height: 1.1;
}
.is-h1 {
  font-size: 60px;
  line-height: 1.2;
}
.is-h2 {
  font-size: 48px;
  line-height: 1.2;
}
.is-h3 {
  font-size: 39px;
  line-height: 1.2;
}
.is-h4 {
  font-size: 31px;
  line-height: 1.2;
}
.is-h5 {
  font-size: 25px;
  line-height: 1.3;
}
.is-h6 {
  font-size: 20px;
  line-height: 1.3;
}
.is-txt-xl {
  font-size: 32px;
  line-height: 1.2;
}
.is-txt-l {
  font-size: 24px;
  line-height: 1.5;
}
.is-txt-m {
  font-size: 16px;
  line-height: 1.5;
}
.is-txt-s {
  font-size: 14px;
  line-height: 1.5;
}
.is-txt-xs {
  font-size: 12px;
  line-height: 1.5;
}

/* Typo Helpers */
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.underlined {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}

/* Buttons */
button {
  outline: none;
  border: none;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  transition: ease background 160ms, color 160ms, opacity 160ms;
}
button:hover {
  cursor: pointer;
}

.btn-prm-m {
  height: 48px;
  width: 100%;
  max-width: 360px;
  background: var(--brand-base);
  border-radius: 48px;
  border: 2px solid var(--brand-base);
  color: var(--000);
}
.btn-prm-m:hover {
  opacity: 0.96;
}

.btn-sec-m {
  height: 48px;
  width: 100%;
  max-width: 360px;
  background: var(--brand-secondary);
  border-radius: 48px;
  border: 2px solid var(--brand-secondary);
  color: var(--000);
}
.btn-sec-m:hover {
  opacity: 0.96;
}

/* Containers */
.container-l {
  margin: 0px auto;
  width: 100%;
  max-width: 1140px;
}
.container-m {
  margin: 0px auto;
  width: 100%;
  max-width: 843px;
}
.container-s {
  margin: 0px auto;
  width: 100%;
  max-width: 624px;
}

/* Grids */
.grid-66 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid-84 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.grid-48 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid-444 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

/* Spacings */
.gap-m {
  gap: 24px;
}
.gap-s {
  gap: 16px;
}
.gap-s {
  gap: 12px;
}
.gap-xs {
  gap: 8px;
}
.gap-xxs {
  gap: 4px;
}

#__next {
  background: var(--000);
  color: var(--800);
}